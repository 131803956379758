<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <PilotCard ref="preview" v-bind:title="title" v-bind:example="true">
          <template v-slot:title>
            <h3 class="card-title">
              <slot name="title">Users Invitation</slot>
            </h3>
          </template>
          <template v-slot:toolbar>
            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="custom-v-dropdown btn btn-clean btn-light-primary btn-sm btn-icon"
              no-caret
              right
              no-flip
            >
              <template v-slot:button-content>
                <i class="ki ki-bold-more-hor"></i>
              </template>
              <div class="navi navi-hover min-w-md-250px">
                <b-dropdown-text tag="div" class="navi-item">
                  <router-link to="/users/invite" v-slot="{ navigate }">
                    <div
                      class="d-flex align-items-center mb-5 cursor-pointer"
                      @click="navigate"
                    >
                      <div class="symbol symbol-40 symbol-light-info mr-5">
                        <span class="symbol-label">
                          <span class="svg-icon svg-icon-lg svg-icon-info">
                            <inline-svg
                              src="media/svg/icons/Code/Plus.svg"
                            ></inline-svg>
                          </span>
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column font-weight-bold text-left"
                      >
                        <a
                          class="text-dark text-hover-primary mb-0 font-size-lg"
                        >
                          New Users
                        </a>
                        <span class="text-muted">
                          Invite new users
                        </span>
                      </div>
                    </div>
                  </router-link>
                </b-dropdown-text>
                <!-- <b-dropdown-text tag="div" class="navi-item">
                  <export-excel
                    class="border-0"
                    :data="users"
                    :fields="exportFields"
                    type="csv"
                    worksheet="users"
                    name="users.csv"
                  >
                    <div class="d-flex align-items-center mb-5 cursor-pointer">
                      <div class="symbol symbol-40 symbol-light-warning mr-5">
                        <span class="symbol-label">
                          <span class="svg-icon svg-icon-lg svg-icon-warning">
                            <inline-svg
                              src="media/svg/icons/Files/Export.svg"
                            ></inline-svg>
                          </span>
                        </span>
                      </div>
                      <div class="d-flex flex-column font-weight-bold">
                        <a
                          class="text-dark text-hover-primary mb-0 font-size-lg"
                        >
                          Export Users
                        </a>
                        <span class="text-muted">
                          Export to excel
                        </span>
                      </div>
                    </div>
                  </export-excel>
                </b-dropdown-text>
                <b-dropdown-text tag="div" class="navi-item">
                  <div
                    @click="$bvModal.show('searchModal')"
                    class="d-flex align-items-center mb-5 cursor-pointer"
                  >
                    <div class="symbol symbol-40 symbol-light-primary mr-5">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-lg svg-icon-primary">
                          <inline-svg
                            src="media/svg/icons/General/Search.svg"
                          ></inline-svg>
                        </span>
                      </span>
                    </div>
                    <div class="d-flex flex-column font-weight-bold">
                      <a class="text-dark text-hover-primary mb-0 font-size-lg">
                        Advanced Search
                      </a>
                      <span class="text-muted">
                        Filter logs
                      </span>
                    </div>
                  </div>
                </b-dropdown-text> -->
                <b-dropdown-text tag="div" class="navi-item">
                  <div
                    @click="refresh()"
                    class="d-flex align-items-center mb-5 cursor-pointer"
                  >
                    <div class="symbol symbol-40 symbol-light-success mr-5">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-lg svg-icon-success">
                          <inline-svg
                            src="media/svg/icons/Media/Repeat.svg"
                          ></inline-svg>
                        </span>
                      </span>
                    </div>
                    <div class="d-flex flex-column font-weight-bold">
                      <a class="text-dark text-hover-primary mb-0 font-size-lg">
                        Refresh
                      </a>
                      <span class="text-muted">
                        Refresh user list
                      </span>
                    </div>
                  </div>
                </b-dropdown-text>
              </div>
            </b-dropdown>
          </template>
          <template v-slot:body>
            <template>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <b-form-input
                    v-model="search"
                    id="inline-form-input-name"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Filter by user name"
                  ></b-form-input>
                </div>
                <div class="col-md-6 col-sm-12">
                  <multiselect
                    v-model.trim="role"
                    :options="roles"
                    track-by="name"
                    label="name"
                    class="select-sm"
                    placeholder="Filter by role"
                    @select="onRoleSelect"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{
                      option.name
                    }}</template>
                  </multiselect>
                </div>
              </div>
              <hr />
              <v-data-table
                :headers="headers"
                :items="invites"
                :items-per-page="10"
                class="elevation-1"
                :search="search"
              >
                <template v-slot:[`item.status`]="{ item }">
                  <span
                    class="label label-inline"
                    :class="getColor(item.status)"
                    >{{ item.status }}</span
                  >
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <div class="d-flex justify-content-end">
                    <b-button
                      size="sm"
                      @click="onResend(item)"
                      variant="primary"
                      class="btn btn-primary btn-sm btn-light-primary font-weight-bolder"
                      >Resend Invite</b-button
                    >
                  </div>
                </template>
              </v-data-table>
            </template>
          </template>
        </PilotCard>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SignUpService from "@/core/services/signup.service";
import PilotCard from "@/view/content/Card.vue";
import Multiselect from "vue-multiselect";
import { GET_INVITATIONS } from "@/core/services/store/signup.module";
//import Swal from "sweetalert2";
export default {
  props: {
    title: String
  },
  data() {
    return {
      invites: [],
      serverInvites: [],
      search: "",
      headers: [
        {
          text: "User",
          align: "start",
          value: "email"
        },
        { text: "Role", value: "role.name" },
        { text: "Status", value: "status" },
        { text: "Actions", align: "end", sortable: false, value: "action" }
      ],
      parent: "",
      role: null,
      roles: []
    };
  },
  components: {
    PilotCard,
    Multiselect
  },
  methods: {
    getColor(status) {
      if (status == "open") return "label-light-danger";
      else if (status == "pending") return "label-light-warning";
      else return "label-light-success";
    },
    onResend(item) {
      SignUpService.resendInvitation(item.id, {})
        .then(response => {
          this.makeToast("success", "User Invitation", response.data.message);
        })
        .catch(error => {
          this.makeToast("error", "Oops", error.data.message);
        });
    },
    onRoleSelect(option) {
      const filterInvites = this.serverInvites.filter(
        i => i.role_id === option.id
      );
      this.invites = filterInvites;
    },
    refresh() {
      this.getInvites();
      this.search = "";
      this.role = null;
    },
    getInvites() {
      this.roles = this.$store.state.roles.roles;
      this.invites = this.$store.state.invite.invitations;
      this.serverInvites = this.$store.state.invite.invitations;
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  },
  computed: {
    /**
     * Check if has title
     * @returns {boolean}
     */
    hasTitleSlot() {
      return !!this.$slots["title"];
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Users" }]);
    this.$store.dispatch(GET_INVITATIONS);
    this.getInvites();
  }
};
</script>
