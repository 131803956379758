var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('PilotCard',{ref:"preview",attrs:{"title":_vm.title,"example":true},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h3',{staticClass:"card-title"},[_vm._t("title",[_vm._v("Users Invitation")])],2)]},proxy:true},{key:"toolbar",fn:function(){return [_c('b-dropdown',{attrs:{"size":"sm","variant":"link","toggle-class":"custom-v-dropdown btn btn-clean btn-light-primary btn-sm btn-icon","no-caret":"","right":"","no-flip":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"ki ki-bold-more-hor"})]},proxy:true}])},[_c('div',{staticClass:"navi navi-hover min-w-md-250px"},[_c('b-dropdown-text',{staticClass:"navi-item",attrs:{"tag":"div"}},[_c('router-link',{attrs:{"to":"/users/invite"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"d-flex align-items-center mb-5 cursor-pointer",on:{"click":navigate}},[_c('div',{staticClass:"symbol symbol-40 symbol-light-info mr-5"},[_c('span',{staticClass:"symbol-label"},[_c('span',{staticClass:"svg-icon svg-icon-lg svg-icon-info"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Code/Plus.svg"}})],1)])]),_c('div',{staticClass:"d-flex flex-column font-weight-bold text-left"},[_c('a',{staticClass:"text-dark text-hover-primary mb-0 font-size-lg"},[_vm._v(" New Users ")]),_c('span',{staticClass:"text-muted"},[_vm._v(" Invite new users ")])])])]}}])})],1),_c('b-dropdown-text',{staticClass:"navi-item",attrs:{"tag":"div"}},[_c('div',{staticClass:"d-flex align-items-center mb-5 cursor-pointer",on:{"click":function($event){return _vm.refresh()}}},[_c('div',{staticClass:"symbol symbol-40 symbol-light-success mr-5"},[_c('span',{staticClass:"symbol-label"},[_c('span',{staticClass:"svg-icon svg-icon-lg svg-icon-success"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Media/Repeat.svg"}})],1)])]),_c('div',{staticClass:"d-flex flex-column font-weight-bold"},[_c('a',{staticClass:"text-dark text-hover-primary mb-0 font-size-lg"},[_vm._v(" Refresh ")]),_c('span',{staticClass:"text-muted"},[_vm._v(" Refresh user list ")])])])])],1)])]},proxy:true},{key:"body",fn:function(){return [[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-sm-12"},[_c('b-form-input',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":"inline-form-input-name","placeholder":"Filter by user name"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"col-md-6 col-sm-12"},[_c('multiselect',{staticClass:"select-sm",attrs:{"options":_vm.roles,"track-by":"name","label":"name","placeholder":"Filter by role"},on:{"select":_vm.onRoleSelect},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.name))]}}]),model:{value:(_vm.role),callback:function ($$v) {_vm.role=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"role"}})],1)]),_c('hr'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.invites,"items-per-page":10,"search":_vm.search},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"label label-inline",class:_vm.getColor(item.status)},[_vm._v(_vm._s(item.status))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"btn btn-primary btn-sm btn-light-primary font-weight-bolder",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.onResend(item)}}},[_vm._v("Resend Invite")])],1)]}}],null,true)})]]},proxy:true}],null,true)})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }